body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* --------- Global ---------- */
* {
  box-sizing: border-box;
}

body {
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  overflow: hidden;
}

/* ------------ Header ---------- */
.header {
  position: fixed;
  width: 800px;
}

.header input {
  font-family: 'Work Sans', sans-serif;
  font-size: 4rem;
  font-weight: 800;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  margin: 1rem;
}

.header input:focus {
  border-width: 0;
  outline: none;
}

h1 {
  font-family: 'Work Sans', sans-serif;
  font-size: 4rem;
  margin: 1rem 1rem 1rem 2rem;
}

/* --------- About --------- */
.about {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.about p {
  margin-left: 2rem;
}

.about img,
.projects img#fish {
position: fixed;
right: 0;
bottom: 5rem;
width: 300px;
margin: 0 75px 0 0;
}

.projects img#fish {
  /* width: 400px; */
}

a#contact, .about p a {
margin: .5rem;
  color: red;
}

.contact p {
  margin: 2rem;
}

/* --------- Projects ---------- */
.projectsContainer {
  font-size: 1.25rem;
  margin: 3rem 0;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.projectList {
  margin: 0.25rem 0 0.25rem 1rem;
}

.projects a,
.project a,
.about a,
.landing a,
.social-footer a {
  font-family: 'Work Sans', sans-serif;
  color: black;
  text-decoration: none;
  margin: 1rem;
}

.projectClass a {
  margin: 0;
  color: red;
}

.projectClass a:hover,
a#contact:hover,
.back a:hover,
.projects a:hover, .about p a:hover {
  color: red;
  border-bottom: 2px solid red;
}

.projectClass {
  margin: 0 0 2rem 0;
  max-width: 90%;
  font-size: 1.25rem;
}

.project img {
  max-width: 90%;
  margin-bottom: 1.5rem;
}

.project {
  font-family: 'Work Sans', sans-serif;
  height: calc(100vh - 5rem);
  width: 50%;
  margin: 1.5rem;
  overflow-y: scroll;
}


.project .title {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 1rem 1rem 1rem 2rem;
}

/* --------- Nav ---------- */

.nav {
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 15em;
  right: -12em;
  color: black;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 500px;
}

ul {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 0 0 0;
}

.nav li {
  margin: 2rem;
  list-style-type: none;
}

li a {
  text-decoration: none;
  color: black;
}

a.active {
  color: red;
  border-bottom: 2px solid red;
}
.nav a:hover {
  color: red;
  border-bottom: 2px solid red;
}

.back {
  margin: 2rem 0;
}

.back a {
  margin: 2rem 0;
}

.homeButton img {
  position: absolute;
  left: 0;
  top: 0;
  width: 10%;
}

/* --------- Footer ---------- */

.social-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  font-size: 1.5rem;
  background-color: rgba(183, 215, 69, 0.5);
  margin: 0;
  padding: 1rem;
  height: 4rem;
}

.social-footer a {
  margin: 0.5rem;
  font-size: 1.25rem;
}

.social-footer .button a:hover {
  color: red !important;
  border-bottom: 2px solid red;
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  body {
    max-width: 100vw;
    overflow-y: scroll;
  }

  h1,
  .header input {
    font-size: 2.25rem;
  }

  .nav,
  nav {
    display: -webkit-flex;
    display: flex;
    -webkit-transform: none;
            transform: none;
    position: fixed;
    width: 100%;
    top: auto;
    top: initial;
    right: auto;
    right: initial;
    bottom: 4rem;
    height: 75px;
  }

  .nav {
    background-color: rgba(183, 215, 69, 0.5);
  }

  .nav img {
    margin: 1rem 1rem 1rem 3rem;
  }

  .nav ul {
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
    margin: 0 0 0 2rem;
  }

  .nav li {
    margin: 1rem;
  }

  img#fish,
  img#soy {
    display: none;
  }

  .project-content,
  .back {
    margin: 1rem;
  }

  .project, .about {
    height: calc(100vh - 15rem);
    overflow-y: scroll;
  }
  
  .project {
    margin: 0 0 100rem 0;
    width: 100%;
  }

  .about {
    width: 90%;
  }

  .social-footer {
    bottom: 0;
  }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {
  img#fish,
  img#soy {
    display: none;
  }
}

